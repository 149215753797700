import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: { 
    margin: 'auto', 
    flexDirection: 'row' 
  },
  tableCol: { 
    width: '25%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCell: { 
    margin: 'auto', 
    marginTop: 5, 
    fontSize: 10 
  }
});

const PDFReport = ({ report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Identity Report</Text>
        
        <Text style={styles.subtitle}>Address Information</Text>
        <Text style={styles.text}>Address: {report.address}</Text>
        <Text style={styles.text}>Balance: {report.balance} ETH</Text>
        <Text style={styles.text}>Is Contract: {report.isContract ? 'Yes' : 'No'}</Text>
        <Text style={styles.text}>Last Activity: {report.lastActivity || 'N/A'}</Text>

        <Text style={styles.subtitle}>Webacy Exposure Risk</Text>
        {report.webacyExposureRisk ? (
          <>
            <Text style={styles.text}>Overall Risk: {report.webacyExposureRisk.overallRisk}</Text>
            <Text style={styles.text}>Total Issues: {report.webacyExposureRisk.count}</Text>
            <Text style={styles.text}>High Risk Issues: {report.webacyExposureRisk.high}</Text>
            <Text style={styles.text}>Medium Risk Issues: {report.webacyExposureRisk.medium}</Text>
          </>
        ) : (
          <Text style={styles.text}>No Webacy Exposure Risk data available.</Text>
        )}

        <Text style={styles.subtitle}>ERC20 Token Balances</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Symbol</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Balance</Text>
            </View>
          </View>
          {report.erc20Tokens.slice(0, 10).map((token, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{token.metadata.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{token.metadata.symbol}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{parseFloat(token.formattedBalance).toFixed(6)}</Text>
              </View>
            </View>
          ))}
        </View>
        {report.erc20Tokens.length > 10 && (
          <Text style={styles.text}>... and {report.erc20Tokens.length - 10} more tokens</Text>
        )}

        <Text style={styles.subtitle}>Fan Tokens</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Symbol</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Balance</Text>
            </View>
          </View>
          {report.fanTokens.slice(0, 10).map((token, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{token.metadata.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{token.metadata.symbol}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{parseFloat(token.formattedBalance).toFixed(6)}</Text>
              </View>
            </View>
          ))}
        </View>
        {report.fanTokens.length > 10 && (
          <Text style={styles.text}>... and {report.fanTokens.length - 10} more fan tokens</Text>
        )}

        <Text style={styles.subtitle}>NFTs</Text>
        {report.nfts && report.nfts.length > 0 ? (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Token ID</Text>
              </View>
            </View>
            {report.nfts.slice(0, 10).map((nft, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{nft.title}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{nft.tokenId}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.text}>No NFTs found.</Text>
        )}
        {report.nfts && report.nfts.length > 10 && (
          <Text style={styles.text}>... and {report.nfts.length - 10} more NFTs</Text>
        )}

        <Text style={styles.subtitle}>Transaction History</Text>
        <Text style={styles.text}>Total Transactions: {report.transactionHistory.length}</Text>
        <Text style={styles.text}>Recent Transactions:</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>From</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>To</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Value</Text>
            </View>
          </View>
          {report.transactionHistory.slice(0, 10).map((tx, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{tx.from.slice(0, 10)}...</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{tx.to.slice(0, 10)}...</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{parseFloat(tx.value).toFixed(6)}</Text>
              </View>
            </View>
          ))}
        </View>
        {report.transactionHistory.length > 10 && (
          <Text style={styles.text}>... and {report.transactionHistory.length - 10} more transactions</Text>
        )}
      </View>
    </Page>
  </Document>
);

export default PDFReport;