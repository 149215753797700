import React, { useState, useCallback, useMemo } from 'react';
import styles from './Report.module.css';
import { ethers } from 'ethers';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import makeBlockie from 'ethereum-blockies-base64';
import { formatDistance } from 'date-fns';

const ITEMS_PER_PAGE = 20;

function Report({ report, onExport }) {
  const [collapsedSections, setCollapsedSections] = useState({});
  const [currentPage, setCurrentPage] = useState({
    erc20Tokens: 1,
    fanTokens: 1,
    nfts: 1,
    transactions: 1
  });
  const [expandedColumns, setExpandedColumns] = useState(false);
  const [filterSpam, setFilterSpam] = useState(false);
  const [hideSmallBalances, setHideSmallBalances] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');

  const toggleSection = useCallback((section) => {
    setCollapsedSections(prev => ({...prev, [section]: !prev[section]}));
  }, []);

  const toggleExpandColumns = useCallback(() => {
    setExpandedColumns(prev => !prev);
  }, []);

  const truncateString = useCallback((str, maxLength = 12) => {
    if (str && str.length > maxLength) {
      return `${str.slice(0, maxLength - 3)}...`;
    }
    return str;
  }, []);

  const filterAndSortTokens = useCallback((tokens) => {
    return tokens
      .filter(token => {
        if (filterSpam) {
          const symbol = token?.metadata?.symbol?.toLowerCase() || '';
          if (symbol.includes('http://') || symbol.includes('https://') || 
              symbol.includes('www.') || symbol.includes('.com') || 
              symbol.includes('.xyz') || symbol.includes('visit')) {
            return false;
          }
        }
        if (hideSmallBalances && parseFloat(token?.formattedBalance || '0') <= 1.00) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const balanceA = parseFloat(a?.formattedBalance || '0');
        const balanceB = parseFloat(b?.formattedBalance || '0');
        return sortOrder === 'asc' ? balanceA - balanceB : balanceB - balanceA;
      });
  }, [filterSpam, hideSmallBalances, sortOrder]);

  const { filteredErc20Tokens, filteredFanTokens } = useMemo(() => {
    return { 
      filteredErc20Tokens: filterAndSortTokens(report.erc20Tokens || []), 
      filteredFanTokens: filterAndSortTokens(report.fanTokens || [])
    };
  }, [report.erc20Tokens, report.fanTokens, filterAndSortTokens]);

  const renderTokenList = useCallback((tokens, type) => {
    const startIndex = (currentPage[type] - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const displayedTokens = tokens.slice(startIndex, endIndex);

    return (
      <div className={styles.tokenList}>
        {displayedTokens.map((token, index) => (
          <div key={index} className={styles.tokenItem}>
            <div className={styles.tokenName}>{token.metadata.name || 'Unknown Token'}</div>
            <div className={styles.tokenBalance}>
              {parseFloat(token.formattedBalance).toFixed(6)} {token.metadata.symbol}
            </div>
          </div>
        ))}
        {renderPagination(tokens.length, currentPage[type], type)}
      </div>
    );
  }, [currentPage]);

  const renderNFTList = useCallback(() => {
    const startIndex = (currentPage.nfts - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const displayedNFTs = report.nfts.slice(startIndex, endIndex);

    return (
      <div className={styles.nftList}>
        {displayedNFTs.map((nft, index) => (
          <div key={index} className={styles.nftItem}>
            <img src={nft.media[0]?.gateway || '/placeholder-image.png'} alt={nft.title} className={styles.nftImage} />
            <div className={styles.nftName}>{truncateString(nft.title, 20)}</div>
            <div className={styles.nftId}>ID: {truncateString(nft.tokenId)}</div>
          </div>
        ))}
        {renderPagination(report.nfts.length, currentPage.nfts, 'nfts')}
      </div>
    );
  }, [report.nfts, currentPage.nfts, truncateString]);

  const renderPagination = useCallback((totalItems, currentPageNum, type) => {
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    return (
      <div className={styles.paginationControls}>
        <button 
          onClick={() => setCurrentPage(prev => ({...prev, [type]: prev[type] - 1}))}
          disabled={currentPageNum === 1}
          className={styles.paginationButton}
        >
          Previous
        </button>
        <span>{currentPageNum} of {totalPages}</span>
        <button 
          onClick={() => setCurrentPage(prev => ({...prev, [type]: prev[type] + 1}))}
          disabled={currentPageNum === totalPages}
          className={styles.paginationButton}
        >
          Next
        </button>
      </div>
    );
  }, []);

  const renderWebacyExposureRisk = useCallback(() => {
    if (!report.webacyExposureRisk) {
      return <p>No Webacy Exposure Risk data available.</p>;
    }

    const { overallRisk, count, high, medium, issues } = report.webacyExposureRisk;

    return (
      <div className={styles.webacyExposureRisk}>
        <h4>Webacy Exposure Risk</h4>
        <p>Overall Risk: {overallRisk}</p>
        <p>Total Issues: {count}</p>
        <p>High Risk Issues: {high}</p>
        <p>Medium Risk Issues: {medium}</p>
        <h5>Detected Issues:</h5>
        <ul>
          {issues.map((issue, index) => (
            <li key={index}>
              <strong>{issue.riskScore}</strong>
              <p>Associated with: {issue.transaction.token_name} ({issue.transaction.token_symbol})</p>
              <p>Contract: {issue.transaction.contract_address}</p>
              <ul>
                {issue.tags.map((tag, tagIndex) => (
                  <li key={tagIndex}>
                    <strong>{tag.name}</strong> (Severity: {tag.severity}): {tag.description}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }, [report.webacyExposureRisk]);

  const renderTransactionHistory = useCallback(() => {
    const startIndex = (currentPage.transactions - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const displayedTransactions = report.transactionHistory.slice(startIndex, endIndex);

    return (
      <div className={styles.transactionHistory}>
        <button onClick={toggleExpandColumns} className={styles.expandButton}>
          {expandedColumns ? 'Collapse Columns' : 'Expand Columns'}
        </button>
        <div className={styles.tableWrapper}>
          <table className={`${styles.transactionTable} ${expandedColumns ? styles.expanded : ''}`}>
            <thead>
              <tr>
                <th>Hash</th>
                <th>From</th>
                <th>To</th>
                <th>Value</th>
                <th>Asset</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {displayedTransactions.map((tx, index) => (
                <tr key={index}>
                  <td>{truncateString(tx.hash, expandedColumns ? undefined : 12)}</td>
                  <td>{truncateString(tx.from, expandedColumns ? undefined : 12)}</td>
                  <td>{truncateString(tx.to, expandedColumns ? undefined : 12)}</td>
                  <td>{parseFloat(tx.value).toFixed(6)}</td>
                  <td>{tx.asset}</td>
                  <td>{formatDistance(new Date(tx.timestamp), new Date(), { addSuffix: true })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {renderPagination(report.transactionHistory.length, currentPage.transactions, 'transactions')}
      </div>
    );
  }, [report.transactionHistory, currentPage.transactions, expandedColumns, truncateString, toggleExpandColumns]);

  const renderAddressIcons = useCallback(() => {
    return (
      <div className={styles.addressIcons}>
        <div className={styles.iconWrapper}>
          <Jazzicon diameter={64} seed={jsNumberForAddress(report.address)} />
        </div>
        <div className={styles.iconWrapper}>
          <img src={makeBlockie(report.address)} alt="Blockie" className={styles.blockies} />
        </div>
      </div>
    );
  }, [report.address]);

  return (
    <div className={styles.report}>
      <h2>Identity Report</h2>
      
      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('addressInfo')}>
          <h3>Address Information</h3>
          <span className={styles.collapseIcon}>{collapsedSections.addressInfo ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.addressInfo && (
          <div className={styles.sectionContent}>
            <div className={styles.addressInfoContent}>
              <div className={styles.addressDetails}>
                <p><strong>Address:</strong> {report.address}</p>
                <p><strong>Balance:</strong> {ethers.formatEther(report.balance)} ETH</p>
                <p><strong>Is Contract:</strong> {report.isContract ? 'Yes' : 'No'}</p>
                <p><strong>Last Activity:</strong> {report.lastActivity ? formatDistance(new Date(report.lastActivity), new Date(), { addSuffix: true }) : 'N/A'}</p>
              </div>
              {renderAddressIcons()}
            </div>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('webacyExposureRisk')}>
          <h3>Webacy Exposure Risk</h3>
          <span className={styles.collapseIcon}>{collapsedSections.webacyExposureRisk ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.webacyExposureRisk && (
          <div className={styles.sectionContent}>
            {renderWebacyExposureRisk()}
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('tokenBalances')}>
          <h3>Token Balances</h3>
          <span className={styles.collapseIcon}>{collapsedSections.tokenBalances ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.tokenBalances && (
          <div className={styles.sectionContent}>
            <div className={styles.filterSortControls}>
              <label>
                <input
                  type="checkbox"
                  checked={filterSpam}
                  onChange={() => setFilterSpam(!filterSpam)}
                />
                Filter Spam Tokens
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={hideSmallBalances}
                  onChange={() => setHideSmallBalances(!hideSmallBalances)}
                />
                Hide Small Balances
              </label>
              <button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')} className={styles.sortButton}>
                Sort by Balance ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
              </button>
            </div>
            <div className={styles.tokenSections}>
              <div className={styles.tokenSection}>
                <h4>ERC20 Tokens</h4>
                {renderTokenList(filteredErc20Tokens, 'erc20Tokens')}
              </div>
              <div className={styles.tokenSection}>
                <h4>Fan Tokens</h4>
                {renderTokenList(filteredFanTokens, 'fanTokens')}
              </div>
            </div>
          </div>
        )}
      </div>

      {report.nfts && report.nfts.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('nfts')}>
            <h3>NFTs</h3>
            <span className={styles.collapseIcon}>{collapsedSections.nfts ? '►' : '▼'}</span>
          </div>
          {!collapsedSections.nfts && (
            <div className={styles.sectionContent}>
              {renderNFTList()}
            </div>
          )}
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('transactionHistory')}>
          <h3>Transaction History</h3>
          <span className={styles.collapseIcon}>{collapsedSections.transactionHistory ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.transactionHistory && (
          <div className={styles.sectionContent}>
            {renderTransactionHistory()}
          </div>
        )}
      </div>

      <button onClick={onExport} className={styles.exportButton}>Export Report</button>
    </div>
  );
}

export default React.memo(Report);