/**
 * api.js
 * This file contains all API-related functions for the NexID application.
 * It handles interactions with Alchemy SDK, Ethers.js, and external APIs.
 */

import { Alchemy, Network } from 'alchemy-sdk';
import { ethers } from 'ethers';
import axios from 'axios';

// Initialize Alchemy SDK for Base network
const alchemyConfig = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.BASE_MAINNET,
};
const alchemy = new Alchemy(alchemyConfig);

// Initialize Infura provider for ENS resolution on Ethereum mainnet
const infuraProvider = new ethers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

// Initialize Axios instance for Webacy API
const webacyApi = axios.create({
  baseURL: 'https://api.webacy.com/v1',
  headers: {
    'accept': 'application/json',
    'x-api-key': process.env.REACT_APP_WEBACY_API_KEY
  }
});

// Error messages
const ERROR_MESSAGES = {
  FETCH_IDENTITY: 'Failed to fetch identity report',
  FETCH_TOKENS: 'Failed to fetch token supplies',
  FETCH_ACTIVITY: 'Failed to fetch last activity',
  FETCH_TX_COUNT: 'Failed to fetch transaction count',
  FETCH_TX_HISTORY: 'Failed to fetch transaction history',
  FETCH_WEBACY: 'Failed to fetch Webacy Exposure Risk',
  FETCH_COMPLETE: 'Failed to fetch complete identity report',
  FETCH_NFTS: 'Failed to fetch NFTs',
  ESTIMATE_GAS: 'Failed to estimate gas cost',
  FETCH_ETH_PRICE: 'Failed to fetch ETH price',
  RESOLVE_ENS: 'Failed to resolve ENS name'
};

// Constants
export const INSIDER_THRESHOLD = 0.5; // 0.5% of total supply for insider classification
export const MAX_ITEMS_PER_PAGE = 100; // Maximum items per page for paginated requests

/**
 * Resolves an ENS name or address to its corresponding Ethereum address
 * @param {string} addressOrEns - ENS name or address to resolve
 * @returns {Promise<string>} Resolved Ethereum address
 */
export const resolveAddress = async (addressOrEns) => {
  try {
    if (ethers.isAddress(addressOrEns)) {
      return addressOrEns;
    }
    const address = await infuraProvider.resolveName(addressOrEns);
    if (!address) {
      throw new Error('Unable to resolve ENS name');
    }
    return address;
  } catch (error) {
    console.error('Error resolving address:', error);
    throw new Error(ERROR_MESSAGES.RESOLVE_ENS);
  }
};

/**
 * Fetches basic identity information for a given address or ENS name
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Object>} Basic identity information
 */
export const fetchIdentityReport = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);

    const [balance, isContract] = await Promise.all([
      alchemy.core.getBalance(address),
      alchemy.core.isContractAddress(address)
    ]);
    
    return {
      address,
      balance: balance.toString(),
      isContract
    };
  } catch (error) {
    console.error('Error fetching identity report:', error);
    throw new Error(ERROR_MESSAGES.FETCH_IDENTITY);
  }
};

/**
 * Fetches token supplies for a given address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Object>} Object containing ERC20 and Fan tokens
 */
export const fetchTokenSupplies = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);

    const tokenBalances = await alchemy.core.getTokenBalances(address);
    
    const tokenDetailsPromises = tokenBalances.tokenBalances.map(async (token) => {
      const metadata = await alchemy.core.getTokenMetadata(token.contractAddress);
      const formattedBalance = ethers.formatUnits(token.tokenBalance, metadata.decimals);
      
      const isFanToken = metadata.symbol?.toLowerCase().includes('id:') || 
                         metadata.symbol?.toLowerCase().includes('cid:') ||
                         metadata.symbol?.toLowerCase().includes('fid:') ||
                         metadata.symbol?.toLowerCase().includes('network:');

      return {
        ...token,
        metadata,
        formattedBalance,
        isFanToken
      };
    });

    const tokenDetails = await Promise.all(tokenDetailsPromises);

    const erc20Tokens = tokenDetails.filter(token => !token.isFanToken);
    const fanTokens = tokenDetails.filter(token => token.isFanToken);

    return { erc20Tokens, fanTokens };
  } catch (error) {
    console.error('Error fetching token supplies:', error);
    return { erc20Tokens: [], fanTokens: [] };
  }
};

/**
 * Fetches the last activity timestamp for a given address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<string|null>} Timestamp of last activity or null if no activity
 */
export const fetchLastActivity = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);

    const transactions = await alchemy.core.getAssetTransfers({
      fromAddress: address,
      category: ["external", "erc20", "erc721", "erc1155"],
      order: "desc",
      maxCount: 1
    });
    return transactions.transfers[0]?.metadata?.blockTimestamp || null;
  } catch (error) {
    console.error('Error fetching last activity:', error);
    throw new Error(ERROR_MESSAGES.FETCH_ACTIVITY);
  }
};

/**
 * Fetches the transaction count for a given address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<number>} Transaction count
 */
export const fetchTransactionCount = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);
    return await alchemy.core.getTransactionCount(address);
  } catch (error) {
    console.error('Error fetching transaction count:', error);
    throw new Error(ERROR_MESSAGES.FETCH_TX_COUNT);
  }
};

/**
 * Fetches transaction history for an address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @param {number} [maxCount=100] - Maximum number of transactions to fetch
 * @returns {Promise<Array>} Array of transactions
 */
export const fetchTransactionHistory = async (addressOrEns, maxCount = 100) => {
  try {
    const address = await resolveAddress(addressOrEns);

    const transfers = await alchemy.core.getAssetTransfers({
      fromAddress: address,
      category: ["external", "erc20", "erc721", "erc1155"],
      order: "desc",
      withMetadata: true,
      maxCount: maxCount
    });
    
    return transfers.transfers.map(tx => ({
      hash: tx.hash,
      from: tx.from,
      to: tx.to,
      value: tx.value,
      asset: tx.asset,
      category: tx.category,
      timestamp: tx.metadata.blockTimestamp
    }));
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    throw new Error(ERROR_MESSAGES.FETCH_TX_HISTORY);
  }
};

/**
 * Fetches Webacy Exposure Risk for a given address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Object>} Webacy Exposure Risk data
 */
export const fetchWebacyExposureRisk = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);
    
    const url = `https://api.webacy.com/quick-profile/${address}?chain=base&withApprovals=true`;
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'x-api-key': process.env.REACT_APP_WEBACY_API_KEY
      }
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    
    return data;
  } catch (error) {
    console.error('Error fetching Webacy Exposure Risk:', error);
    return {
      overallRisk: "Unknown",
      count: 0,
      high: 0,
      medium: 0,
      issues: []
    };
  }
};

/**
 * Fetches NFTs owned by an address
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Array>} Array of NFTs
 */
export const fetchNFTs = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);

    const nftsResponse = await alchemy.nft.getNftsForOwner(address);
    
    return nftsResponse.ownedNfts.map(nft => ({
      tokenId: nft.tokenId,
      title: nft.title,
      description: nft.description,
      media: nft.media,
      contract: {
        address: nft.contract.address,
        name: nft.contract.name,
        symbol: nft.contract.symbol
      }
    }));
  } catch (error) {
    console.error('Error fetching NFTs:', error);
    return [];
  }
};

/**
 * Fetches complete identity report for a given address or ENS name
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Object>} Complete identity report data
 */
export const fetchCompleteIdentityReport = async (addressOrEns) => {
  try {
    console.log(`Fetching complete identity report for address or ENS: ${addressOrEns}`);
    const address = await resolveAddress(addressOrEns);

    const [basicInfo, tokenSupplies, lastActivity, transactionCount, transactionHistory, webacyData, nfts] = await Promise.all([
      fetchIdentityReport(address),
      fetchTokenSupplies(address),
      fetchLastActivity(address),
      fetchTransactionCount(address),
      fetchTransactionHistory(address),
      fetchWebacyExposureRisk(address),
      fetchNFTs(address)
    ]);

    console.log(`Successfully fetched complete identity report for address: ${address}`);
    return {
      ...basicInfo,
      erc20Tokens: tokenSupplies.erc20Tokens,
      fanTokens: tokenSupplies.fanTokens,
      lastActivity,
      transactionCount,
      transactionHistory,
      webacyExposureRisk: webacyData,
      nfts
    };
  } catch (error) {
    console.error(`Error fetching complete identity report for address ${addressOrEns}:`, error);
    return {
      address: addressOrEns,
      error: ERROR_MESSAGES.FETCH_COMPLETE,
      partialData: true
    };
  }
};

/**
 * Calculates the total value of tokens in USD
 * @param {Array} tokens - Array of token objects
 * @returns {number} Total value in USD
 */
export const calculateTotalTokenValue = (tokens) => {
  return tokens.reduce((total, token) => {
    const price = token.metadata.price?.value || 0;
    return total + (parseFloat(token.formattedBalance) * price);
  }, 0);
};

/**
 * Identifies potential insider holdings based on token balance percentage
 * @param {Array} tokens - Array of token objects
 * @returns {Array} Array of potential insider holdings
 */
export const identifyInsiderHoldings = (tokens) => {
  return tokens.filter(token => {
    if (!token.metadata.totalSupply) return false;
    const percentage = (parseFloat(token.formattedBalance) / parseFloat(token.metadata.totalSupply)) * 100;
    return percentage >= INSIDER_THRESHOLD;
  });
};

/**
 * Analyzes NFT collections for rarity and estimated value
 * @param {Array} nfts - Array of NFT objects
 * @returns {Promise<Array>} Array of analyzed NFT collections
 */
export const analyzeNFTCollections = async (nfts) => {
  const collections = nfts.reduce((acc, nft) => {
    if (!acc[nft.contract.address]) {
      acc[nft.contract.address] = {
        name: nft.contract.name,
        count: 0,
        items: []
      };
    }
    acc[nft.contract.address].count++;
    acc[nft.contract.address].items.push(nft);
    return acc;
  }, {});

  const analyzedCollections = await Promise.all(Object.values(collections).map(async (collection) => {
    try {
      const floorPrice = await alchemy.nft.getFloorPrice(collection.items[0].contract.address);
      return {
        ...collection,
        floorPrice: floorPrice.openSea?.floorPrice || null,
        estimatedValue: (floorPrice.openSea?.floorPrice || 0) * collection.count
      };
    } catch (error) {
      console.error(`Error fetching floor price for collection ${collection.name}:`, error);
      return collection;
    }
  }));

  return analyzedCollections.sort((a, b) => b.estimatedValue - a.estimatedValue);
};

/**
 * Fetches and analyzes transaction patterns
 * @param {string} address - Ethereum address
 * @returns {Promise<Object>} Transaction pattern analysis
 */
export const analyzeTransactionPatterns = async (address) => {
  const transactions = await fetchTransactionHistory(address, 1000);
  
  const patterns = {
    totalTransactions: transactions.length,
    incomingTransactions: 0,
    outgoingTransactions: 0,
    uniqueContractsInteracted: new Set(),
    mostFrequentInteraction: { address: null, count: 0 },
    averageTransactionValue: 0,
    largestTransaction: { value: 0, hash: null }
  };

  const interactionCounts = {};
  let totalValue = 0;

  transactions.forEach(tx => {
    if (tx.from.toLowerCase() === address.toLowerCase()) {
      patterns.outgoingTransactions++;
    } else {
      patterns.incomingTransactions++;
    }

    patterns.uniqueContractsInteracted.add(tx.to);

    interactionCounts[tx.to] = (interactionCounts[tx.to] || 0) + 1;
    if (interactionCounts[tx.to] > patterns.mostFrequentInteraction.count) {
      patterns.mostFrequentInteraction = { address: tx.to, count: interactionCounts[tx.to] };
    }

    const value = parseFloat(tx.value);
    totalValue += value;
    if (value > patterns.largestTransaction.value) {
      patterns.largestTransaction = { value, hash: tx.hash };
    }
  });

  patterns.uniqueContractsInteracted = patterns.uniqueContractsInteracted.size;
  patterns.averageTransactionValue = totalValue / transactions.length;

  return patterns;
};

/**
 * Fetches complete identity report with advanced analysis
 * @param {string} addressOrEns - Ethereum address or ENS name
 * @returns {Promise<Object>} Complete identity report with advanced analysis
 */
export const fetchCompleteIdentityReportWithAnalysis = async (addressOrEns) => {
  try {
    const address = await resolveAddress(addressOrEns);
    const basicReport = await fetchCompleteIdentityReport(address);
    
    const [nftCollections, transactionPatterns] = await Promise.all([
      analyzeNFTCollections(basicReport.nfts),
      analyzeTransactionPatterns(address)
    ]);

    const insiderHoldings = identifyInsiderHoldings([...basicReport.erc20Tokens, ...basicReport.fanTokens]);
    const totalTokenValue = calculateTotalTokenValue([...basicReport.erc20Tokens, ...basicReport.fanTokens]);

    return {
      ...basicReport,
      advancedAnalysis: {
        nftCollections,
        transactionPatterns,
        insiderHoldings,
        totalTokenValue
      }
    };
  } catch (error) {
    console.error(`Error fetching complete identity report with analysis for address ${addressOrEns}:`, error);
    throw new Error(ERROR_MESSAGES.FETCH_COMPLETE);
  }
};

/**
 * Estimates gas costs for a transaction
 * @param {Object} transaction - Transaction object
 * @returns {Promise<Object>} Estimated gas cost in wei and USD
 */
export const estimateGasCost = async (transaction) => {
  try {
    const gasPrice = await alchemy.core.getGasPrice();
    const estimatedGas = await alchemy.core.estimateGas(transaction);
    const gasCostWei = gasPrice.mul(estimatedGas);
    const gasCostEth = ethers.formatEther(gasCostWei);
    
    // Fetch current ETH price in USD
    const ethPrice = await fetchEthPrice();
    const gasCostUSD = parseFloat(gasCostEth) * ethPrice;

    return {
      wei: gasCostWei.toString(),
      eth: gasCostEth,
      usd: gasCostUSD.toFixed(2)
    };
  } catch (error) {
    console.error('Error estimating gas cost:', error);
    throw new Error(ERROR_MESSAGES.ESTIMATE_GAS);
  }
};

/**
 * Fetches the current ETH price in USD
 * @returns {Promise<number>} Current ETH price in USD
 */
const fetchEthPrice = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
    return response.data.ethereum.usd;
  } catch (error) {
    console.error('Error fetching ETH price:', error);
    throw new Error(ERROR_MESSAGES.FETCH_ETH_PRICE);
  }
};

// Improved rate limiting function
const rateLimiter = (() => {
  const queue = [];
  const intervalMs = 1000; // 1 second interval
  const maxRequestsPerInterval = 3; // Reduced from 5 to 3

  setInterval(() => {
    const now = Date.now();
    while (queue.length > 0 && now - queue[0] >= intervalMs) {
      queue.shift();
    }
  }, intervalMs);

  return async (fn) => {
    while (queue.length >= maxRequestsPerInterval) {
      await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms before checking again
    }
    queue.push(Date.now());
    return fn();
  };
})();

// Wrap API calls with rate limiter
export const fetchIdentityReportWithRateLimit = (addressOrEns) => rateLimiter(() => fetchIdentityReport(addressOrEns));
export const fetchTokenSuppliesWithRateLimit = (addressOrEns) => rateLimiter(() => fetchTokenSupplies(addressOrEns));
export const fetchLastActivityWithRateLimit = (addressOrEns) => rateLimiter(() => fetchLastActivity(addressOrEns));
export const fetchTransactionCountWithRateLimit = (addressOrEns) => rateLimiter(() => fetchTransactionCount(addressOrEns));
export const fetchWebacyExposureRiskWithRateLimit = (addressOrEns) => rateLimiter(() => fetchWebacyExposureRisk(addressOrEns));
export const fetchTransactionHistoryWithRateLimit = (addressOrEns, maxCount) => rateLimiter(() => fetchTransactionHistory(addressOrEns, maxCount));
export const fetchCompleteIdentityReportWithRateLimit = (addressOrEns) => rateLimiter(() => fetchCompleteIdentityReport(addressOrEns));
export const fetchNFTsWithRateLimit = (addressOrEns) => rateLimiter(() => fetchNFTs(addressOrEns));
export const fetchCompleteIdentityReportWithAnalysisWithRateLimit = (addressOrEns) => rateLimiter(() => fetchCompleteIdentityReportWithAnalysis(addressOrEns));
export const estimateGasCostWithRateLimit = (transaction) => rateLimiter(() => estimateGasCost(transaction));

// Export all necessary functions and objects
export {
  alchemy,
  infuraProvider,
  webacyApi,
  ERROR_MESSAGES
};