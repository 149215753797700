import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFReport from './PDFReport';
import styles from './ExportModal.module.css';

const ExportModal = ({ report, onClose }) => {
  const handleJSONExport = () => {
    const filename = `identity_report.json`;
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(report, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Export Identity Report</h2>
        <p>Choose your export format:</p>
        <div className={styles.buttonGroup}>
          <button onClick={handleJSONExport} className={styles.exportButton}>Export as JSON</button>
          <PDFDownloadLink
            document={<PDFReport report={report} />}
            fileName="identity_report.pdf"
            className={styles.exportButton}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Generating PDF...' : 'Export as PDF'
            }
          </PDFDownloadLink>
          <button onClick={onClose} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;