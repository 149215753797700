import React, { useState, useCallback, useMemo } from 'react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Report from './components/Report';
import TokenReport from './components/TokenReport';
import ExportModal from './components/ExportModal';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import useTheme from './hooks/useTheme';
import styles from './App.module.css';
import { 
  fetchCompleteIdentityReportWithRateLimit, 
  fetchCompleteIdentityReportWithAnalysisWithRateLimit,
  fetchTokenSuppliesWithRateLimit
} from './api';

const ITEMS_PER_PAGE = 20;

function App() {
  const [searchInput, setSearchInput] = useState('');
  const [searchType, setSearchType] = useState('identity');
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [currentPageERC20, setCurrentPageERC20] = useState(1);
  const [currentPageFanTokens, setCurrentPageFanTokens] = useState(1);
  const [currentPageNFTs, setCurrentPageNFTs] = useState(1);
  const [filterSpam, setFilterSpam] = useState(false);
  const [hideSmallBalances, setHideSmallBalances] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');

  const { theme, toggleTheme } = useTheme();

  const handleSearch = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setReport(null);

    try {
      let searchAddress = searchInput.trim();
      console.log("Starting search for:", searchAddress, "Type:", searchType);

      if (searchType === 'identity') {
        const reportData = await fetchCompleteIdentityReportWithAnalysisWithRateLimit(searchAddress);
        console.log("Received identity report:", reportData);
        if (reportData.error) {
          throw new Error(reportData.error);
        }
        setReport(reportData);
      } else {
        const tokenData = await fetchTokenSuppliesWithRateLimit(searchAddress);
        console.log("Received token report:", tokenData);
        if (tokenData.error) {
          throw new Error(tokenData.error);
        }
        setReport(tokenData);
      }
    } catch (err) {
      console.error('Error during search:', err);
      setError(err.message || 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [searchInput, searchType]);

  const isFanToken = useCallback((token) => {
    const symbol = token?.metadata?.symbol?.toLowerCase() || '';
    return symbol.includes('cid:') || symbol.includes('fid:') || symbol.includes('network:') || symbol.includes('id:');
  }, []);

  const sortTokens = useCallback((tokens) => {
    return [...tokens].sort((a, b) => {
      const balanceA = parseFloat(a.formattedBalance);
      const balanceB = parseFloat(b.formattedBalance);
      return sortOrder === 'asc' ? balanceA - balanceB : balanceB - balanceA;
    });
  }, [sortOrder]);

  const { erc20Tokens, fanTokens, nfts } = useMemo(() => {
    if (!report) return { erc20Tokens: [], fanTokens: [], nfts: [] };
  
    let erc20 = report.erc20Tokens || [];
    let fan = report.fanTokens || [];
    let nfts = report.nfts || [];
  
    // Filter and sort ERC20 tokens
    const filteredERC20 = erc20.filter(token => {
      if (filterSpam) {
        const symbol = token?.metadata?.symbol?.toLowerCase() || '';
        if (symbol.includes('http://') || symbol.includes('https://') || 
            symbol.includes('www.') || symbol.includes('.com') || 
            symbol.includes('.xyz') || symbol.includes('visit')) {
          return false;
        }
      }
      if (hideSmallBalances) {
        return parseFloat(token.formattedBalance) > 1.00;
      }
      return true;
    });
  
    const sortedERC20 = sortTokens(filteredERC20);
    const sortedFan = sortTokens(fan);
  
    console.log('Filtered ERC20 Tokens:', sortedERC20.length);
    console.log('Fan Tokens:', sortedFan.length);
  
    return {
      erc20Tokens: sortedERC20,
      fanTokens: sortedFan,
      nfts
    };
  }, [report, filterSpam, hideSmallBalances, sortTokens]);

  const totalPagesERC20 = useMemo(() => Math.ceil(erc20Tokens.length / ITEMS_PER_PAGE), [erc20Tokens]);
  const totalPagesFanTokens = useMemo(() => Math.ceil(fanTokens.length / ITEMS_PER_PAGE), [fanTokens]);
  const totalPagesNFTs = useMemo(() => Math.ceil(nfts.length / ITEMS_PER_PAGE), [nfts]);

  const handlePageChangeERC20 = useCallback((newPage) => {
    setCurrentPageERC20(newPage);
  }, []);

  const handlePageChangeFanTokens = useCallback((newPage) => {
    setCurrentPageFanTokens(newPage);
  }, []);

  const handlePageChangeNFTs = useCallback((newPage) => {
    setCurrentPageNFTs(newPage);
  }, []);

  const toggleFilterSpam = useCallback(() => {
    setFilterSpam(prev => !prev);
  }, []);

  const toggleHideSmallBalances = useCallback(() => {
    setHideSmallBalances(prev => !prev);
  }, []);

  const toggleSortOrder = useCallback(() => {
    setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
  }, []);

  return (
    <ErrorBoundary>
      <div className={`${styles.app} ${styles[theme]}`}>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <main className={styles.container}>
          <div className={styles.searchTypeToggle}>
            <button
              onClick={() => setSearchType('identity')}
              className={searchType === 'identity' ? styles.active : ''}
            >
              Identity Search
            </button>
            <button
              onClick={() => setSearchType('token')}
              className={searchType === 'token' ? styles.active : ''}
            >
              Token Search
            </button>
          </div>
          <SearchBar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            onSearch={handleSearch}
            loading={loading}
            placeholder={searchType === 'identity' ? "Enter Ethereum Address or ENS name" : "Enter Token Contract Address"}
          />
          {error && <div className={styles.errorAlert}>{error}</div>}
          {loading && <LoadingSpinner />}
          {report && !loading && (
            searchType === 'identity' ? (
              <Report
                report={report}
                erc20Tokens={erc20Tokens}
                fanTokens={fanTokens}
                nfts={nfts}
                currentPageERC20={currentPageERC20}
                totalPagesERC20={totalPagesERC20}
                onPageChangeERC20={handlePageChangeERC20}
                currentPageFanTokens={currentPageFanTokens}
                totalPagesFanTokens={totalPagesFanTokens}
                onPageChangeFanTokens={handlePageChangeFanTokens}
                currentPageNFTs={currentPageNFTs}
                totalPagesNFTs={totalPagesNFTs}
                onPageChangeNFTs={handlePageChangeNFTs}
                filterSpam={filterSpam}
                toggleFilterSpam={toggleFilterSpam}
                hideSmallBalances={hideSmallBalances}
                toggleHideSmallBalances={toggleHideSmallBalances}
                sortOrder={sortOrder}
                toggleSortOrder={toggleSortOrder}
                onExport={() => setShowExportModal(true)}
              />
            ) : (
              <TokenReport
                report={report}
                onExport={() => setShowExportModal(true)}
              />
            )
          )}
          {showExportModal && (
            <ExportModal
              report={report}
              reportType={searchType}
              onClose={() => setShowExportModal(false)}
            />
          )}
        </main>
        <footer className={styles.footer}>
          <p>© 2024 NexID. All rights reserved.</p>
        </footer>
      </div>
    </ErrorBoundary>
  );
}

export default App;